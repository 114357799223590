<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.businessSettings') }}</h1>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>
    <GeneralSettings v-if="activeTab === 0" :is-admin="isAdmin" />
    <PaymentSettings v-if="activeTab === 1" :is-admin="isAdmin" />
  </PageLayout>
</template>

<script type="text/javascript">
import { ref } from 'vue';

import { PageLayout, Tabs } from '@/modules/core';
import { PaymentSettings, GeneralSettings } from './components';
import { useUser } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';

export default {
  components: { PageLayout, Tabs, PaymentSettings, GeneralSettings },
  setup() {
    const { isAdmin } = useUser();

    const { hasPaymentView } = useGlobalPermissions();
    return {
      hasPaymentView,
      isAdmin,
      activeTab: ref(0),
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('businessSettings.tabs.general'),
        },
        this.hasPaymentView && {
          text: this.$t('businessSettings.tabs.paymentSettings'),
        },
      ];
    },
  },
};
</script>
