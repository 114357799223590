<template>
  <div v-loading="loading">
    <h3 class="text-typography-primary mb-4">{{ translate('title') }}</h3>
    <ul class="list-group">
      <li class="list-group-item px-4 py-0">
        <div class="row d-flex align-items-center">
          <div class="col-2">
            <p class="fw-bold">
              {{ translate('table.headers.logo') }}
            </p>
          </div>
          <div class="col d-flex justify-content-between align-items-center">
            <template v-if="logo">
              <img :src="`${logo}&token=${token}`" width="32" height="32" />
            </template>
            <template v-else>
              <img src="@/assets/images/business.png" width="32" height="32" />
            </template>
            <Button v-if="isAdmin" type="text" @click="settingsModalType = 'logo'">
              {{ $t('businessSettings.actions.edit') }}
            </Button>
          </div>
        </div>
      </li>
      <SettingsListItem
        v-for="item in settingsListItems"
        :key="item.type"
        :header="translate(`table.headers.${item.type}`)"
        :type="item.type"
        :button-enabled="isAdmin && item.buttonEnabled"
        :button-text="$t('businessSettings.actions.edit')"
        :value="item.value"
        @click="settingsModalType = item.type"
      />
    </ul>
    <GeneralSettingsModal
      v-if="settingsModalType"
      :business="business"
      :type="settingsModalType"
      :token="token"
      :show-conflict="showConflict"
      @close="closeGeneralSettingsModal"
      @update="handleBusinessUpdate"
      @removeLogo="handleRemoveLogo"
    />
  </div>
</template>

<script type="text/javascript">
import { ref, getCurrentInstance } from 'vue';
import { isNil, omit, reject } from 'ramda';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import { SettingsListItem, GeneralSettingsModal } from './general-settings';
import { useBusinessPatch } from '../compositions/business';

export default {
  components: { GeneralSettingsModal, SettingsListItem, Button },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  setup() {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { currentTenant: business, token } = useTenancy();

    const settingsModalType = ref(null);
    const showConflict = ref(null);

    const {
      updateBusiness,
      loading,
      onDone: onBusinessPatchSuccess,
      onError: onBusinessPatchError,
    } = useBusinessPatch();

    onBusinessPatchSuccess(() => {
      settingsModalType.value = null;
      $message.success($i18n.t('businessSettings.generalSettings.messages.businessUpdateSuccess'));
    });
    onBusinessPatchError((err) => {
      if (err.graphQLErrors?.[0].extensions.response?.status === 409) {
        showConflict.value = true;
        $message.error($i18n.t(`businessSettings.generalSettings.messages.${settingsModalType.value}Conflict`));
      } else {
        $message.error($i18n.t('businessSettings.generalSettings.messages.businessUpdateError'));
      }
    });

    return {
      business,
      loading,
      updateBusiness,
      settingsModalType,
      token,
      showConflict,
    };
  },
  computed: {
    logo() {
      return this.business?.logoUrl;
    },
    settingsListItems() {
      return [
        {
          type: 'name',
          buttonEnabled: true,
          value: this.business.name,
        },
        {
          type: 'legalName',
          buttonEnabled: true,
          value: this.business.legalName,
        },
        {
          type: 'number',
          buttonEnabled: true,
          value: this.business.number,
        },
        {
          type: 'address',
          buttonEnabled: true,
          value: this.business.contact.address,
        },
        {
          type: 'phone',
          buttonEnabled: true,
          value: this.business.contact.phone,
        },
        {
          type: 'email',
          buttonEnabled: false,
          value: this.business.tenant ? `${this.business.tenant}@clarityo.com` : '-',
        },
        {
          type: 'primaryContact',
          buttonEnabled: true,
          value: this.business.contact.name,
        },
      ];
    },
  },
  methods: {
    translate(key, count, args = null) {
      return args
        ? this.$tc(`businessSettings.generalSettings.${key}`, count, args)
        : this.$t(`businessSettings.generalSettings.${key}`);
    },
    async handleRemoveLogo() {
      this.settingsModalType = null;
      this.showConflict = false;
      await this.updateBusiness({ businessId: this.business.id, patchParams: { logoUrl: null } });
    },
    async handleBusinessUpdate(data) {
      this.showConflict = false;
      const settingsType = this.settingsModalType;
      let businessUpdateParams;

      switch (settingsType) {
        case 'name':
        case 'legalName':
        case 'number':
          businessUpdateParams = { [settingsType]: data };
          break;
        case 'address':
        case 'phone':
          businessUpdateParams = {
            contact: { ...omit(['__typename'], reject(isNil)(this.business.contact)), [settingsType]: data },
          };
          break;
        case 'primaryContact':
          businessUpdateParams = {
            contact: { ...omit(['__typename'], reject(isNil)(this.business.contact)), name: data },
          };
          break;
        case 'logo':
          businessUpdateParams = { logoUrl: data.url };
          break;
      }
      await this.updateBusiness({ businessId: this.business.id, patchParams: reject(isNil)(businessUpdateParams) });
    },
    closeGeneralSettingsModal() {
      this.settingsModalType = null;
      this.showConflict = false;
    },
  },
};
</script>

<style lang="scss" scoped>
li .row {
  height: 54px;
}
</style>
