<template>
  <el-dialog width="507px" visible append-to-body :before-close="close" :show-close="false" custom-class="rounded">
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            <h2>{{ $t('businessSettings.paymentSettings.newCreditCard') }}</h2>
          </span>
          <span>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="close">
              <CloseIcon />
            </Button>
          </span>
        </div>
      </div>
    </template>
    <div>
      <div class="container g-0 mb-6">
        <el-form ref="form" :model="formModel" class="mb-4" :show-message="false">
          <div class="row">
            <el-form-item prop="type" class="m-0 col-4" required>
              <small class="text-muted">
                {{ $t('payment.paymentMethodModal.labels.cardType') }}
              </small>
              <el-select v-model="formModel.type" filterable class="w-100">
                <el-option
                  v-for="type in CREDIT_CARD_TYPE"
                  :key="type"
                  :value="type"
                  :label="`${$t(`payment.paymentMethodModal.labels.${type}`)} `"
                >
                  <VisaIcon v-if="type === 'visa'" />
                  <MastercardIcon v-if="type === 'mastercard'" />
                  <AmericanExpressIcon v-if="type === 'americanExpress'" />
                  <IsracardIcon v-if="type === 'isracard'" />
                  <DinersIcon v-if="type === 'diners'" />
                  {{ $t(`payment.paymentMethodModal.labels.${type}`) }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="number" class="m-0 col-3" :rules="{ min: 4 }" required>
              <small class="text-muted">
                {{ $t('payment.paymentMethodModal.labels.lastFourDigits') }}
              </small>
              <el-input :value="formModel.number" type="number" @input="handleCreditCardNumber" />
            </el-form-item>
            <el-form-item prop="cardOwner" class="m-0 col-5" required>
              <small class="text-muted">
                {{ $t('payment.paymentMethodModal.labels.cardOwner') }}
              </small>
              <el-input v-model="formModel.cardOwner" />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="d-flex justify-content-end">
        <Button type="secondary" @click="close">
          {{ $t('commons.cancel') }}
        </Button>
        <Button @click="handleApplyButtonClick">
          {{ $t('commons.apply') }}
        </Button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CREDIT_CARD_TYPE } from '@/modules/payment/types';

import { CloseIcon, VisaIcon, MastercardIcon, AmericanExpressIcon, IsracardIcon, DinersIcon } from '@/assets/icons';

export default {
  components: {
    Button,
    CloseIcon,
    VisaIcon,
    MastercardIcon,
    AmericanExpressIcon,
    IsracardIcon,
    DinersIcon,
  },
  props: {
    initialPaymentMethod: { type: Object, default: () => null },
    supplierAccount: { type: Object, default: () => null },
  },
  data() {
    return {
      formModel: {
        type: '',
        number: '',
        cardOwner: '',
      },
      CREDIT_CARD_TYPE,
    };
  },
  methods: {
    handleCreditCardNumber(v) {
      if (v.match('^[0-9]{0,4}$')) this.formModel.number = v;
    },
    async handleApplyButtonClick() {
      const valid = await this.$refs.form.validate().catch((err) => err);
      if (!valid) return;

      this.$emit('create', this.formModel);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 1em;
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
