<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h3>{{ $t('businessSettings.tabs.paymentSettings') }}</h3>
      <el-tooltip
        v-if="isUSClient && bankAccounts?.length > 0"
        effect="dark"
        :content="$t('businessSettings.tooltips.restrictedToOneBankAccount')"
        placement="top"
      >
        <Button disabled>
          {{ $t('businessSettings.paymentSettings.manage') }}
        </Button>
      </el-tooltip>
      <Button v-else-if="isUSClient && bankAccounts?.length === 0" @click="isPlaidLinkOpen = true">
        {{ $t('businessSettings.paymentSettings.manage') }}
      </Button>
    </div>
    <div v-loading="loading">
      <p class="fw-bold my-4">{{ translate('bankAccount') }}</p>
      <ul class="list-group">
        <li v-if="bankDetails" class="list-group-item px-4 py-2">
          <div class="row d-flex align-items-center">
            <div class="col-2">
              <p class="fw-bold"><BankTransferIcon /> {{ bankTitle }}</p>
            </div>
            <div class="col d-flex justify-content-between align-items-center">
              <p class="text-typography-primary">
                {{ bankDetailsTitle }}
              </p>
              <div v-if="isAdmin">
                <Button
                  v-if="bankDetails.filePathUrl"
                  type="text"
                  class="fw-normal"
                  @click="isPreviewReferenceOpen = true"
                >
                  <p>{{ $t('businessSettings.paymentSettings.reference') }}</p>
                </Button>
                <Button
                  type="text"
                  v-if="isAdmin"
                  :disabled="!hasPaymentManageBankAccounts"
                  :class="{
                    disabled: !hasPaymentManageBankAccounts,
                  }"
                  class="fw-normal"
                  @click="() => handleDeleteBankAccount(bankDetails.id)"
                >
                  <p class="text-danger">{{ $t('commons.remove') }}</p>
                </Button>
              </div>
            </div>
          </div>
        </li>
        <li v-else-if="isAdmin" class="list-group-item d-flex align-items-center justify-content-end px-4 py-2">
          <Button v-if="!isUSClient" type="text" class="fw-normal py-1" @click="isBankAccountModalOpen = true">
            <p><PlusIcon />{{ translate('bankAccount') }}</p>
          </Button>
        </li>
      </ul>
      <p class="fw-bold my-4">{{ translate('creditCards') }}</p>
      <ul class="list-group">
        <template v-if="creditCards.length">
          <li v-for="(creditCard, index) in creditCards" :key="index" class="list-group-item px-4 py-2">
            <div class="row d-flex align-items-center">
              <div class="col-2">
                <p class="fw-bold">
                  <VisaIcon v-if="creditCard.type === CREDIT_CARD_TYPE.VISA" />
                  <MastercardIcon v-if="creditCard.type === CREDIT_CARD_TYPE.MASTERCARD" />
                  <AmericanExpressIcon v-if="creditCard.type === CREDIT_CARD_TYPE.AMERICAN_EXPRESS" />
                  <IsracardIcon v-if="creditCard.type === CREDIT_CARD_TYPE.ISRACARD" />
                  <DinersIcon v-if="creditCard.type === CREDIT_CARD_TYPE.DINERS" />
                  {{ $t(`payment.paymentMethodModal.labels.${creditCard.type}`) }}
                </p>
              </div>
              <div class="col d-flex justify-content-between align-items-center">
                <p class="text-typography-primary">
                  {{ `xxxx-xxxx-xxxx-${creditCard.number}, ${creditCard.cardOwner}` }}
                </p>
                <Button
                  v-if="isAdmin"
                  :disabled="!hasPaymentManageCreditCards"
                  :class="{
                    disabled: !hasPaymentManageCreditCards,
                  }"
                  type="text"
                  class="fw-normal"
                  @click="() => handleDeleteCreditCard(creditCard.id)"
                >
                  <p class="text-danger">{{ $t('commons.remove') }}</p>
                </Button>
              </div>
            </div>
          </li>
          <li
            v-if="isAdmin"
            :disabled="!hasPaymentManageCreditCards"
            :class="{
              disabled: !hasPaymentManageCreditCards,
            }"
            class="list-group-item d-flex align-items-center justify-content-end px-4 py-2"
          >
            <Button type="text" class="fw-normal py-1" @click="isCreditCardModalOpen = true">
              <p><PlusIcon />{{ $t('businessSettings.actions.additionalCard') }}</p>
            </Button>
          </li>
        </template>
        <li v-else-if="isAdmin" class="list-group-item d-flex align-items-center justify-content-end px-4 py-2">
          <Button type="text" class="fw-normal py-1" @click="isCreditCardModalOpen = true">
            <p><PlusIcon />{{ $t('businessSettings.actions.creditCard') }}</p>
          </Button>
        </li>
      </ul>
      <AddBankAccountModal
        v-if="isBankAccountModalOpen"
        :supplier-name="currentTenant.name"
        :business-id="currentTenant.id"
        :business-company-id="currentTenant.number"
        :country-code="countryCode"
        :supported-banks="supportedBanks"
        @close="isBankAccountModalOpen = false"
      />
      <CreditCardModal
        v-if="isCreditCardModalOpen"
        @close="isCreditCardModalOpen = false"
        @create="handleCreateCreditCard"
      />
      <el-dialog
        v-if="isPreviewReferenceOpen"
        top="2%"
        custom-class="rounded"
        visible
        :show-close="false"
        @close="isPreviewReferenceOpen = false"
      >
        <template #title>
          <div class="d-flex justify-content-between px-2">
            <h3>{{ $t('businessSettings.paymentSettings.reference') }}</h3>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="isPreviewReferenceOpen = false">
              <CloseIcon />
            </Button>
          </div>
        </template>
        <div class="px-6" style="height: 80vh">
          <embed width="100%" height="100%" name="plugin" :src="bankDetails.filePathUrl" type="application/pdf" />
        </div>
        <template #footer><div style="height: 8px"></div></template>
      </el-dialog>
      <PlaidLink
        v-if="isPlaidLinkOpen"
        :token="plaidLinkToken"
        :on-success="onPlaidLinkSuccess"
        :on-exit="onPlaidLinkExit"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, getCurrentInstance, onMounted } from 'vue';

import { Button } from '@/modules/core';
import {
  PlusIcon,
  BankTransferIcon,
  VisaIcon,
  MastercardIcon,
  AmericanExpressIcon,
  IsracardIcon,
  DinersIcon,
  CloseIcon,
} from '@/assets/icons';
import { useTenancy } from '@/modules/auth';
import {
  useCreditCards,
  useCreateCreditCard,
  useDeleteCreditCard,
  useBankAccounts,
  AddBankAccountModal,
  useBankAccountDelete,
  plaidLinkCreate,
  PlaidLink,
  paymentMethodsConnectionCreate,
  useSupportedBanks,
} from '@/modules/payment';
import { CREDIT_CARD_TYPE } from '@/modules/payment/types';
import { useGlobalPermissions } from '@/modules/permissions';

import CreditCardModal from './CreditCardModal';

export default {
  components: {
    Button,
    PlusIcon,
    BankTransferIcon,
    VisaIcon,
    MastercardIcon,
    AmericanExpressIcon,
    IsracardIcon,
    DinersIcon,
    CloseIcon,
    AddBankAccountModal,
    CreditCardModal,
    PlaidLink,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const businessId = computed(() => currentTenant.value.id);
    const { bankAccounts, loading: bankAccountsLoading } = useBankAccounts(businessId);
    const { creditCards, loading: creditCardsLoading, refetch: creditCardsRefetch } = useCreditCards(businessId);
    const countryCode = computed(() => currentTenant.value?.countryCode);
    const { supportedBanks } = useSupportedBanks(countryCode);

    const { hasPaymentManageCreditCards, hasPaymentManageBankAccounts } = useGlobalPermissions();

    const {
      createCreditCard,
      onDone: createCreditCardOnDone,
      onError: createCreditCardOnError,
    } = useCreateCreditCard();

    const { deleteBankAccount } = useBankAccountDelete();

    createCreditCardOnError(() => root.$message.error(root.$t('businessSettings.messages.creditCardAddFailed')));
    createCreditCardOnDone(() => root.$message.success(root.$t('businessSettings.messages.creditCardAddSuccess')));

    const { deleteCreditCard, onDone: deleteCreditCardOnDone } = useDeleteCreditCard();

    deleteCreditCardOnDone(() => root.$message.success(root.$t('businessSettings.messages.creditCardDeleteSuccess')));

    const { createPlaidLink } = plaidLinkCreate();
    const { createPaymentMethodsConnection } = paymentMethodsConnectionCreate();

    const plaidLinkToken = ref(null);
    const setPlaidLinkToken = async () => {
      const {
        data: {
          plaidLinkCreate: { linkToken },
        },
      } = await createPlaidLink();
      plaidLinkToken.value = linkToken;
    };

    const isUSClient = computed(() => (currentTenant.value.countryCode ?? 'IL') === 'US');

    onMounted(async () => {
      if (isUSClient.value) await setPlaidLinkToken();
    });
    const forceLoading = ref(false);
    const bankDetails = computed(() => bankAccounts.value?.[0]);

    const bankDetailsTitle = computed(() => {
      const { accountName, branchNumber, accountNumber, routingNumber } = bankDetails.value;
      const addBankAccountModal = 'payment.addBankAccountModal';
      const bankAccountNumberTranslation = root.$t('businessSettings.paymentSettings.bankAccountNumber');

      const ilFormat = `${accountName}, ${bankAccountNumberTranslation} ${branchNumber}-${accountNumber}`;
      const usFormat = `${root.$t(`${addBankAccountModal}.bankAccountName`)}: ${accountName}, ${root.$t(
        `${addBankAccountModal}.bankRoutingNumber`
      )}: ${routingNumber}, ${bankAccountNumberTranslation}: ${accountNumber}`;

      return countryCode.value === 'IL' ? ilFormat : usFormat;
    });

    const bankTitle = computed(() => {
      return countryCode.value === 'IL'
        ? supportedBanks.value[bankDetails.value?.bankNumber]
        : bankDetails.value?.routingNumber;
    });

    return {
      hasPaymentManageCreditCards,
      hasPaymentManageBankAccounts,
      countryCode,
      bankAccounts,
      supportedBanks,
      isBankAccountModalOpen: ref(false),
      isCreditCardModalOpen: ref(false),
      isPreviewReferenceOpen: ref(false),
      bankDetailsTitle,
      bankDetails,
      bankTitle,
      creditCards,
      CREDIT_CARD_TYPE,
      currentTenant,
      createCreditCard,
      creditCardsRefetch,
      createCreditCardOnDone,
      deleteCreditCard,
      deleteCreditCardOnDone,
      deleteBankAccount,
      loading: computed(() => bankAccountsLoading.value || creditCardsLoading.value || forceLoading.value),
      isUSClient,
      createPlaidLink,
      isPlaidLinkOpen: ref(false),
      plaidLinkToken,
      setPlaidLinkToken,
      forceLoading,
      createPaymentMethodsConnection,
    };
  },
  methods: {
    translate(key) {
      return this.$t(`businessSettings.paymentSettings.${key}`);
    },
    async handleCreateCreditCard(creditCard) {
      await this.createCreditCard({ createParams: { ...creditCard, businessId: this.currentTenant.id } });
      this.isCreditCardModalOpen = false;
      await this.creditCardsRefetch();
    },
    async handleDeleteCreditCard(id) {
      this.$confirm(this.$i18n.t('commons.areYouSure'), { type: 'warning' })
        .then(async () => {
          await this.deleteCreditCard({ id });
          await this.creditCardsRefetch();
        })
        .catch(() => null);
    },
    async handleDeleteBankAccount(id) {
      this.$confirm(
        this.$t('supplierAccountDialog.tabs.paymentMethod.deleteConfirmationText'),
        this.$t('supplierAccountDialog.tabs.paymentMethod.deleteConfirmationTitle'),
        {
          confirmButtonText: this.$t('supplierAccountDialog.tabs.paymentMethod.confirmDelete'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: this.$t('cancel'),
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(async () => {
          await this.deleteBankAccount({ id }, { refetchQueries: ['bankAccounts'] });
        })
        .catch(() => null);
    },
    async onPlaidLinkSuccess(public_token) {
      this.forceLoading = true;
      await this.createPaymentMethodsConnection(
        { createParams: { businessId: this.currentTenant.id, publicToken: public_token } },
        { refetchQueries: ['bankAccounts'] }
      );
      this.isPlaidLinkOpen = false;
      this.forceLoading = false;
    },
    onPlaidLinkExit(error) {
      this.isPlaidLinkOpen = false;
      if (error) this.setPlaidLinkToken();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
</style>
